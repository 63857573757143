.row {
  display: flex;
  background: #ccc;
  width: 100%;
}
.row:first-child .brick {
  margin-top: 4px;
}
.row:last-child .brick {
  margin-bottom: 4px;
}
.row .brick:nth-child(even) {
  background: #aaf;
  width: 10%;
}
.row:nth-child(even) .brick:first-child {
  display: none;
}
.row:nth-child(even) .brick:last-child {
  margin-right: 2px;
}
.row:nth-child(even)::before,
.row:nth-child(even)::after {
  position: relative;
  content: "";
  background: #bd5858;
  width: calc(15%/2);
  height: 3vw;
  border-radius: 2px;
  margin: 2px;
  box-shadow: 0 0 19px 1px #7d3232 inset;
}
.row:nth-child(even)::before {
  margin-left: 4px;
}
.row:nth-child(even)::after {
  background: #aaf;
  margin-right: 4px;
}
.brick {
  background: #bd5858;
  width: 15%;
  height: 3vw;
  border-radius: 2px;
  margin: 2px;
  box-shadow: 0 0 19px 1px #7d3232 inset;
}
.brick:last-child {
  margin-right: 4px;
}
.brick:first-child {
  margin-left: 4px;
}
